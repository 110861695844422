export const GetWebApiBaseUri = () => {
    const uri = process.env.REACT_APP_API_URL;
    return uri;
};

export const GetRSAPublicKey = () => {
    return process.env.REACT_APP_RSA_PUBLIC_KEY;
};

export const GetLoginRedirectUri = () => {
    const uri = process.env.REACT_APP_APPLICATION_URL;
    return uri;
};

export const GetLogoutRedirectUri = () => {
    const uri = process.env.REACT_APP_APPLICATION_URL;
    return uri;
};

export const GetMsalClientId = () => {
    return "3e40300f-c27c-4688-b0bc-6070f51b384b";
};

export const GetMsalClientScope = () => {
    return [
        "openid",
        "profile",
        "api://f165aaac-ec3f-4dfa-bd66-dc28eaa6e709/access_as_user",
        "offline_access",
    ];
};

export const GetMsalGraphApiScope = () => {
    return ["User.Read", "User.ReadBasic.All"];
};

export const GetMsalTenantAuthorityUri = () => {
    return "https://login.windows.net/cef04b19-7776-4a94-b89b-375c77a8f936";
};

export const GetMsalCacheLocation = () => {
    return "sessionStorage";
};

export const GetMsalStoreAuthInCookie = () => {
    const stringValue = "false";

    if (stringValue.toLowerCase() === "true") {
        return true;
    } else if (stringValue.toLowerCase() === "false") {
        return false;
    } else {
        throw new Error(
            "MSAL_AUTH_STATE_IN_COOKIE setting is not a valid boolean."
        );
    }
};
