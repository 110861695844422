import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import SkipNextRoundedIcon from "@mui/icons-material/SkipNextRounded";
import SkipPreviousRoundedIcon from "@mui/icons-material/SkipPreviousRounded";
import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import MaterialTable from "material-table";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { AppData } from "../../services/Auth/AppData";
import { AccountContext } from "../../services/Auth/MsalAuth";

import "./Report.css";

const CustomPaginationComponent = (props) => {
    const {
        page,
        rowsPerPage,
        count,
        onPageChange,
        onRowsPerPageChange,
        rowsPerPageOptions,
    } = props;

    let from = rowsPerPage * page + 1;
    let to = rowsPerPage * (page + 1);
    if (to > count) {
        to = count;
    }
    return (
        <td>
            <Grid container alignItems="center" style={{ padding: 10 }}>
                <Grid item>
                    <Typography variant="caption" style={{ color: "white" }}>
                        {" "}
                        Rows per page:
                    </Typography>
                </Grid>

                <Grid item>
                    <FormControl variant="standard" sx={{ minWidth: 60, ml: 2 }}>
                        <Select
                            value={rowsPerPage}
                            onChange={onRowsPerPageChange}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                        >
                            {rowsPerPageOptions.map((x, i) => (
                                <MenuItem value={x} key={i}>
                                    {x}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <IconButton disabled={page === 0} onClick={(e) => onPageChange(e, 0)}>
                        <SkipPreviousRoundedIcon
                            fontSize="small"
                            style={{ color: page === 0 ? "gray" : "white" }}
                        />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton
                        disabled={page === 0}
                        onClick={(e) => onPageChange(e, page - 1)}
                    >
                        <NavigateBeforeRoundedIcon
                            fontSize="small"
                            style={{ color: page === 0 ? "gray" : "white" }}
                        />
                    </IconButton>
                </Grid>
                <Grid item>
                    <Typography
                        variant="caption"
                        style={{ color: "white", margin: "0 1rem" }}
                    >
                        {from}-{to} of {count}
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton
                        disabled={to >= count}
                        onClick={(e) => onPageChange(e, page + 1)}
                    >
                        <NavigateNextRoundedIcon
                            fontSize="small"
                            style={{ color: to < count ? "white" : "gray" }}
                        />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton
                        disabled={to >= count}
                        onClick={(e) => onPageChange(e, count)}
                    >
                        <SkipNextRoundedIcon
                            fontSize="small"
                            style={{ color: to >= count ? "gray" : "white" }}
                        />
                    </IconButton>
                </Grid>
            </Grid>
        </td>
    );
};

const useStyles = makeStyles({
    colHeader: {
        "&:hover": {
            color: "#f5e003",
        },
    },
});

const buttonStyling = {
    background: "#f5e003",
    color: "black",
    fontFamily: "Amiko",
    fontWeight: "700",
    margin: "1rem",
    "&:hover": {
        background: "#f5e003",
        color: "black",
    },
};

const Report = () => {
    const classes = useStyles();
    const account = useContext(AccountContext);
    const appData = new AppData(account);
    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [startDate, setStartDate] = useState(
        moment("2022-10-07", "YYYY-MM-DD")
    );
    const [endDate, setEndDate] = useState(moment());

    const ColumnHeader = ({ title }) => {
        return <div className={classes.colHeader}>{title}</div>;
    };

    const downloadFormData = () => {
        let excelColumns = [
            "SlNo.",
            "Tracking Number",
            "Contact Name",
            "Email",
            "Company Name",
            "Country Of Registration",
            "Countries Of Operation",
            "Business Years",
            "D&B Number",
            "Has existing relationship with ABInBev?",
            "Existing relationship details",
            "Company Size",
            "Product/Service Offered",
            "Other Products",
            "Is Company Certified?",
            "Organisation Certified By",
            "Other Organisation Name",
        ];
        let excelData = [];
        reports.map(
            (
                {
                    trackingNumber,
                    contactName,
                    email,
                    companyName,
                    countryReg,
                    operationalCountriesCsv,
                    businessYrs,
                    dnbNumber,
                    isExistingRelationYesNo,
                    existingRelationDtls,
                    companySize,
                    productsOfferedCsv,
                    otherProductOffered,
                    isOwnedByDiverseYesNo,
                    diverseCertBy,
                    diverseCertByOther,
                },
                index
            ) => {
                excelData.push({
                    slno: index + 1,
                    trackingNumber,
                    contactName,
                    email,
                    companyName,
                    countryReg,
                    operationalCountriesCsv,
                    businessYrs,
                    dnbNumber,
                    isExistingRelationYesNo,
                    existingRelationDtls,
                    companySize,
                    productsOfferedCsv,
                    otherProductOffered,
                    isOwnedByDiverseYesNo,
                    diverseCertBy,
                    diverseCertByOther,
                });
            }
        );

        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Reports");
        XLSX.utils.sheet_add_aoa(worksheet, [excelColumns], { origin: "A1" });
        XLSX.writeFile(workbook, "Reports.xlsx");
    };

    const fetchDniReport = () => {
        setLoading(true);

        const payload = {
            startDate: moment(startDate).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
        };

        appData
            .getDniReport(payload)
            .then((response) => {
                let dniForms = response.data.data.dniForms;
                dniForms = dniForms.map((form, index) => {
                    return {
                        ...form,
                        index: index + 1,
                        operationalCountriesCsv: form.operationalCountries.toString(),
                        productsOfferedCsv: form.productsOffered.toString(),
                        isExistingRelationYesNo: form.isExistingRelation ? "Yes" : "No",
                        isOwnedByDiverseYesNo: form.isOwnedByDiverse ? "Yes" : "No",
                    };
                });
                setReports(dniForms);
            })
            .catch((error) => {
                console.log(error);
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (!appData.authToken.apiToken) {
            return;
        }
        fetchDniReport();
    }, [startDate, endDate, appData.authToken.apiToken]);

    const handleStartDateChange = (value) => {
        setStartDate(value);
    };

    const handleEndDateChange = (value) => {
        setEndDate(value);
    };

    return (
        <div className="report-container">
            {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Grid container marginBottom={2} style={{ padding: "0 20rem" }}>
                        <Grid item xs={6}>
                            <Typography style={{ color: "white" }} align="center">
                                Start Date
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography style={{ color: "white" }} align="center">
                                End Date
                            </Typography>
                        </Grid>
                        <Grid item xs={6} display="flex" justifyContent="center">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DesktopDatePicker
                                    inputFormat="YYYY-MM-DD"
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} display="flex" justifyContent="center">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DesktopDatePicker
                                    inputFormat="YYYY-MM-DD"
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Typography
                        variant="h6"
                        component="p"
                        color="white"
                        align="center"
                        style={{
                            fontFamily: `"Roboto","Helvetica","Arial", sans-serif`,
                            marginBottom: "1rem",
                        }}
                    >
                        Total registered suppliers in this date range are {reports.length}
                    </Typography>
                    <Paper
                        variant="outlined"
                        square
                        style={{
                            border: "2px solid white",
                            borderRadius: "10px",
                            background: "black",
                        }}
                    >
                        <h5>
                            <span>SUPPLIER INFORMATION</span>
                        </h5>
                        <MaterialTable
                            components={{
                                Pagination: (props) => (
                                    <div
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <Button
                                            variant="contained"
                                            sx={buttonStyling}
                                            onClick={downloadFormData}
                                        >
                                            Export To Excel
                                        </Button>
                                        <CustomPaginationComponent {...props} />
                                    </div>
                                ),
                            }}
                            style={{
                                border: "2px solid white",
                                margin: "0 3rem 2rem",
                                background: "black",
                            }}
                            options={{
                                showTitle: false,
                                search: true,
                                headerStyle: {
                                    background: "black",
                                    color: "white",
                                    zIndex: "0",
                                    padding: "9px",
                                    fontFamily: `"Roboto","Helvetica","Arial", sans-serif`,
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    whiteSpace: "nowrap",
                                },
                                rowStyle: {
                                    fontFamily: `"Roboto","Helvetica","Arial", sans-serif`,
                                    background: "black",
                                    color: "white",
                                    fontSize: "13px",
                                    whiteSpace: "nowrap",
                                },
                            }}
                            columns={[
                                {
                                    title: <ColumnHeader title="SNo." />,
                                    field: "index",
                                },
                                {
                                    title: <ColumnHeader title="Tracking Number" />,
                                    field: "trackingNumber",
                                },
                                {
                                    title: <ColumnHeader title="Contact Name" />,
                                    field: "contactName",
                                },
                                {
                                    title: <ColumnHeader title="Email" />,
                                    field: "email",
                                },
                                {
                                    title: <ColumnHeader title="Company Name" />,
                                    field: "companyName",
                                },
                                {
                                    title: <ColumnHeader title="Country Of Registration" />,
                                    field: "countryReg",
                                },
                                {
                                    title: <ColumnHeader title="Countries Of Operation" />,
                                    field: "operationalCountriesCsv",
                                },
                                {
                                    title: <ColumnHeader title="Business Years" />,
                                    field: "businessYrs",
                                },
                                {
                                    title: <ColumnHeader title="D&B Number" />,
                                    field: "dnbNumber",
                                },
                                {
                                    title: (
                                        <ColumnHeader title="Has existing relationship with ABInBev?" />
                                    ),
                                    field: "isExistingRelationYesNo",
                                },
                                {
                                    title: <ColumnHeader title="Existing relationship details" />,
                                    field: "existingRelationDtls",
                                },
                                {
                                    title: <ColumnHeader title="Company Size" />,
                                    field: "companySize",
                                },
                                {
                                    title: <ColumnHeader title="Product/Service Offered" />,
                                    field: "productsOfferedCsv",
                                },
                                {
                                    title: <ColumnHeader title="Other Products" />,
                                    field: "otherProductOffered",
                                },
                                {
                                    title: <ColumnHeader title="Is Company Certified?" />,
                                    field: "isOwnedByDiverseYesNo",
                                },
                                {
                                    title: <ColumnHeader title="Organisation Certified By" />,
                                    field: "diverseCertBy",
                                },
                                {
                                    title: <ColumnHeader title="Other Organisation Name" />,
                                    field: "diverseCertByOther",
                                },
                            ]}
                            data={reports}
                        />
                    </Paper>
                </>
            )}
        </div>
    );
};

export default Report;
