import * as msal from "@azure/msal-browser";
import React, { createContext, useCallback, useEffect, useState } from "react";
import {
    GetLoginRedirectUri,
    GetLogoutRedirectUri,
    GetMsalClientId,
    GetMsalClientScope,
    GetMsalGraphApiScope,
    GetMsalTenantAuthorityUri,
    GetWebApiBaseUri
} from "./AppSettingsService";

let accountId = null;
export const AccountContext = createContext(null);
const baseUri = GetWebApiBaseUri();

const msalConfig = {
    auth: {
        clientId: GetMsalClientId(),
        authority: GetMsalTenantAuthorityUri(),
        redirectUri: GetLoginRedirectUri(),
        postLogoutRedirectUri: GetLogoutRedirectUri(),
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: "false",
    },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

const loginRequest = {
    scopes: GetMsalClientScope(),
    graphScopes: GetMsalGraphApiScope(),
};

export const logout = () => {
    const logoutRequest = {
        account: msalInstance.getAccountByHomeId(accountId),
    };

    msalInstance.logoutRedirect(logoutRequest);
};

export const login = async () => {
    try {
        const response = await msalInstance.loginRedirect({
            scopes: loginRequest.scopes.concat(loginRequest.graphScopes),
        });
        console.log(response);
    } catch (err) {
        console.log(err);
    }
};

export const requestSilentTokens = async () => {
    try {
        const [accessTokenResponse, graphTokenResponse] = await Promise.allSettled([
            msalInstance.acquireTokenSilent({ scopes: loginRequest.scopes }),
            msalInstance.acquireTokenSilent({ scopes: loginRequest.graphScopes }),
        ]);

        const apiToken = accessTokenResponse.status === "fulfilled" ? accessTokenResponse.value.accessToken : null;
        const graphToken = graphTokenResponse.status === "fulfilled" ? graphTokenResponse.value.accessToken : null;
        const tokenExpirationDate = accessTokenResponse.status === "fulfilled" ? accessTokenResponse.value.expiresOn : null;

        return { apiToken, graphToken, tokenExpirationDate };
    } catch (error) {
        console.error(error);
        return { apiToken: null, graphToken: null, tokenExpirationDate: null };
    }
};

export const MsalAuthProvider = ({ children }) => {
    const [account, setAccount] = useState({
        user: {
            userId: null,
            userGuid: null,
            programs: [],
        },
        apiToken: "",
        graphToken: "",
        tokenExpirationDate: null,
    });
    const [authStatus, setAuthStatus] = useState("");

    const setMsalAccount = useCallback((resp) => {
        if (resp !== null) {
            accountId = resp.account.homeAccountId;
            msalInstance.setActiveAccount(resp.account);
            setAccount(prevAccount => ({ ...prevAccount, user: resp.account }));
            setAuthStatus("authorized");
            requestSilentToken();
        } else {
            const currentAccounts = msalInstance.getAllAccounts();

            if (!currentAccounts || currentAccounts.length < 1) {
                msalInstance.loginRedirect({
                    ...loginRequest,
                    prompt: "none",
                });
            } else {
                const activeAccount = currentAccounts[0];
                msalInstance.setActiveAccount(activeAccount);
                accountId = activeAccount.homeAccountId;
                requestSilentToken();
            }
        }
    }, []);

    const requestSilentToken = useCallback(async () => {
        try {
            const accessTokenResponse = await msalInstance.acquireTokenSilent({
                scopes: loginRequest.scopes,
            });
            const headers = new Headers();
            const bearerToken = `Bearer ${accessTokenResponse.accessToken}`;

            headers.append("Authorization", bearerToken);

            const options = {
                method: "GET",
                headers: headers,
            };

            await fetch(`${baseUri}Ping`, options)
                .then(async (resp) => {
                    if (resp.status === 403) {
                        setAuthStatus("unauthorized");
                        return;
                    }
                    setAuthStatus("authorized");
                    // console.log(resp);
                    //Graph API requests required a dedicated token with specific scopes
                    await msalInstance
                        .acquireTokenSilent({ scopes: loginRequest.graphScopes })
                        .then((graphTokenResponse) => {
                            const accountData = {
                                ...account,
                                apiToken: accessTokenResponse.accessToken,
                                graphToken: graphTokenResponse.accessToken,
                                tokenExpirationDate: accessTokenResponse.expiresOn,
                            };
                            setAccount(accountData);
                        });
                })
                .catch((resp_1) => {
                    setAuthStatus("unauthorized");
                });
        } catch (error) {
            console.error(error);
        }
    }, [account]);

    useEffect(() => {
        msalInstance
            .handleRedirectPromise()
            .then(setMsalAccount)
            .catch((err) => {
                if (err.errorCode === "login_required") {
                    setAuthStatus("logged-out");
                }
            });
    }, [setMsalAccount]);

    return (
        <AccountContext.Provider value={{ authStatus, account }}>
            {children}
        </AccountContext.Provider>
    );
};
