import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { login } from "../../services/Auth/MsalAuth";

import "./Login.css";

const buttonStyling = {
    minWidth: "264px",
    background: "black",
    color: "white",
    border: "2px solid white",
    marginTop: "1rem",
    "&:hover": {
        color: "#F5E003 ",
        background: "black ",
        border: "2px solid #F5E003",
    },
    "&:disabled": {
        color: "gray",
        border: "2px solid gray",
    },
};

const Title = ({ text, variant, component }) => (
    <Typography
        variant={variant}
        component={component}
        style={{
            fontFamily: "Amiko",
            fontSize: "40px",
            fontWeight: "700",
            fontStyle: "normal",
            color: "#F5E003",
        }}
    >
        {text}
    </Typography>
);

const Login = () => {
    const navigate = useNavigate();

    const onClickRecordTrackHandler = () => {
        navigate("/track");
    };

    const handleSSOLogin = (e) => {
        e.preventDefault();
        login();
    }

    return (
        <>
            <Box
                sx={{
                    boxShadow: 20,
                    height: "100%",
                }}
            >
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }}
                >
                    <div className="landing-page-description">
                        <Title text="Supplier DEI Portal" variant="h5" component="p" />
                        <Button
                            sx={buttonStyling}
                            onClick={() => {
                                navigate("/registration");
                            }}
                        >
                            DIVERSE VENDOR REGISTRATION
                        </Button>
                        <Button sx={buttonStyling} onClick={onClickRecordTrackHandler}>
                            RECORD TRACKING
                        </Button>
                        <Button sx={buttonStyling} onClick={handleSSOLogin}>
                            SSO LOGIN
                        </Button>
                        <div className="footer-section">
                            <Typography
                                variant="h6"
                                component="p"
                                color="white"
                                fontSize="0.75rem"
                                justifyContent="center"
                                lineHeight="1rem"
                                margin="10px 15px"
                            >
                                This form is for informational purposes only. Submitting any
                                information on this form does not guarantee that you will receive
                                any business or procurement opportunity from AB InBev or its
                                affiliates, nor does it guarantee that you will be considered for
                                any such business or opportunity. This is not a bid, nor an
                                invitation to bid, on procurement opportunities. Submission of
                                this form does not make your business an approved supplier of AB
                                InBev or its affiliates. Nothing in this form is a(n) guarantee,
                                promise, or indication of intent to award a contract, enter into
                                negotiations, make an offer, certify or classify your business in
                                any way, or invite you to bid on any procurement opportunities.
                                For current suppliers of AB InBev or its affiliates, submission of
                                the information in this form does not guarantee that you will
                                continue to be an approved supplier, nor is this a(n) guarantee,
                                promise, or indication of intent to continue, extend, renew, or
                                modify any relationship or contract.
                            </Typography>
                        </div>
                    </div>
                </Grid>
            </Box>
        </>
    );
};

export default Login;
