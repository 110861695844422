import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../../services/Auth/MsalAuth";

const textStyling = {
    margin: "2rem 0",
};

const buttonStyling = {
    marginTop: "1rem",
    background: "white",
    color: "black",
    fontFamily: "Amiko",
    fontWeight: "700",
    "&:hover": {
        background: "white",
        color: "black",
    },
};

const TrackingId = ({ trackingId }) => {
    const navigate = useNavigate();
    const { authStatus } = useContext(AccountContext);

    const handleBackBtnClicked = () => {
        authStatus === "authorized" ? navigate("/report") : navigate("/");
    };

    return (
        <Box style={{ margin: "8% 15%" }}>
            <Paper
                variant="outlined"
                square
                style={{
                    border: "2px solid white",
                    borderRadius: "10px",
                    background: "black",
                    padding: "0 50px"
                }}
            >
                <Typography
                    variant="h6"
                    component="p"
                    align="center"
                    color="white"
                    sx={textStyling}
                >
                    Thanks for submitting to the ABI DEI supplier register.
                </Typography>
                <Typography
                    variant="h6"
                    component="p"
                    align="center"
                    color="white"
                    sx={textStyling}
                    fontSize="1.05rem"
                >
                    We have received your registration, and will contact you in case we believe there's an opportunity for your company. Please note that, as per our Terms and Conditions displayed in the website, your submission to the forms does not guarantee a working opportunity, which is under ABI's discretion
                </Typography>
                <Typography
                    variant="h5"
                    component="p"
                    align="center"
                    color="white"
                    sx={textStyling}
                >
                    The tracking ID for this record is{" "}
                    <span style={{ color: "#F5E003" }}>{trackingId}</span>
                </Typography>
                <Typography
                    variant="h6"
                    component="p"
                    align="center"
                    color="white"
                    sx={textStyling}
                >
                    Details of tracking ID have also been shared to your email
                </Typography>
            </Paper>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Button
                    variant="contained"
                    sx={buttonStyling}
                    onClick={handleBackBtnClicked}
                >
                    BACK
                </Button>
            </Grid>
        </Box>
    );
};

export default TrackingId;
