import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Pagination,
    Paper,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import { Stack } from "@mui/system";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RecaptchaDialog from "../../components/RecaptchaDialog/RecaptchaDialog";

import TrackingId from "../../components/TrackingId/TrackingId";
import TrackingIdUpdated from "../../components/TrackingId/TrackingIdUpdated";
import { AppData } from "../../services/Auth/AppData";
import { AccountContext } from "../../services/Auth/MsalAuth";
import "./Form.css";
import { validationSchema } from "./FormSchema";

const formLabelStyling = {
    color: "white",
    fontSize: "14px",
};

const buttonStyling = {
    marginTop: "1rem",
    background: "#F5E003",
    color: "black",
    fontFamily: "Amiko",
    fontWeight: "700",
    "&:hover": {
        background: "#F5E003",
        color: "black",
    },
};

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiPaginationItem-icon": {
            backgroundColor: "white",
        },
        "& .Mui-selected": {
            backgroundColor: "#F5E003 !important",
            color: "black",
        },
        "& ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected)":
        {
            backgroundColor: "gray !important",
            color: "black",
        },
    },
}));

const Form = () => {
    const classes = useStyles();
    const appData = new AppData();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { authStatus } = useContext(AccountContext);
    const [loading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [companySize, setCompanySize] = useState([]);
    const [countries, setCountries] = useState([]);
    const [products, setProducts] = useState([]);
    const [certifiedCompanies, setCertifiedCompanies] = useState([]);
    const [trackingId, setTrackingId] = useState("");
    const [submittedFormType, setSubmittedFormType] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [captchaValue, setCaptchaValue] = useState("");
    const captchaRef = useRef(null);

    const resetCaptcha = () => {
        setCaptchaValue("");
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const onVerifyCaptchaHandler = (value) => {
        setCaptchaValue(value);
        handleClose();
        dniFormSubmitApiHandler();
    };

    const dniFormSubmitApiHandler = () => {
        setIsLoading(true);
        let postData = {
            contactName: formik.values.contactName,
            email: formik.values.email,
            companyName: formik.values.companyName,
            countryRegId: formik.values.countryOfRegistration,
            businessYrs: Number(formik.values.businessYears),
            operationalCountries: formik.values.countriesOfOperation,
            dnbNumber: formik.values.dAndBNumber,
            isExistingRelation:
                formik.values.existingRelationshipWithAbi === "yes" ? true : false,
            existingRelationDtls: formik.values.existingRelationshipWithAbiDetails,
            companySizeId: formik.values.companySize,
            productsOffered: formik.values.productsOffered,
            otherProductOffered: formik.values.otherProductsOffered,
            isOwnedByDiverse:
                formik.values.isCompanyCertified === "yes" ? true : false,
            diverseCertById:
                formik.values.certifiedCompany === ""
                    ? null
                    : formik.values.certifiedCompany,
            diverseCertByOther: formik.values.otherOrganizationName,
        };

        if (state?.mode === "edit") {
            postData = { trackingNumber: state?.trackingNumber, ...postData };
            appData
                .updateDniForm(postData)
                .then((response) => {
                    setSubmittedFormType("edit");
                })
                .catch((error) => {
                    console.log(error);
                    console.error(error);
                })
                .finally(() => {
                    setIsLoading(false);
                    resetCaptcha();
                });
        } else {
            appData
                .addDniFormRecord(postData)
                .then((response) => {
                    setSubmittedFormType("new");
                    setTrackingId(response.data.data.trackingNumber);
                })
                .catch((error) => {
                    console.log(error);
                    console.error(error);
                })
                .finally(() => {
                    setIsLoading(false);
                    resetCaptcha();
                });
        }
    };

    const handleFormSubmit = () => {
        setOpenDialog(true);
    };

    const formik = useFormik({
        initialValues: {
            contactName: "",
            email: "",
            companyName: "",
            countryOfRegistration: "",
            countriesOfOperation: [],
            businessYears: "",
            dAndBNumber: "",
            existingRelationshipWithAbi: "yes",
            existingRelationshipWithAbiDetails: "",
            isCompanyCertified: "yes",
            companySize: "",
            productsOffered: [],
            otherProductsOffered: "",
            certifiedCompany: "",
            otherOrganizationName: "",
        },
        validationSchema: validationSchema,
        onSubmit: handleFormSubmit,
    });

    const fetchDropdownData = () => {
        setIsLoading(true);
        Promise.all([
            appData.getCompanySize(),
            appData.getCountry(),
            appData.getProduct(),
            appData.getCertifiedCompany(),
        ])
            .then((response) => {
                setCompanySize(response[0].data.data);
                setCountries(response[1].data.data);
                setProducts(response[2].data.data);
                let certCompanies = response[3].data.data;
                certCompanies.sort((a, b) => a.name.localeCompare(b.name));
                const othersIndex = certCompanies.findIndex((object) => {
                    return object.name === "Others";
                });
                certCompanies.push(certCompanies.splice(othersIndex, 1)[0]);
                setCertifiedCompanies(certCompanies);
            })
            .catch((error) => {
                console.log(error);
                console.error(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleInputChange = (key, value) => {
        switch (key) {
            case "existingRelationshipWithAbi":
                formik.setValues({
                    ...formik.values,
                    existingRelationshipWithAbiDetails: "",
                    [key]: value,
                });
                break;
            case "productsOffered":
                formik.setValues({
                    ...formik.values,
                    otherProductsOffered: "",
                    [key]: value,
                });
                break;
            case "isCompanyCertified":
                formik.setValues({
                    ...formik.values,
                    certifiedCompany: "",
                    otherOrganizationName: "",
                    [key]: value,
                });
                break;
            case "certifiedCompany":
                formik.setValues({
                    ...formik.values,
                    otherOrganizationName: "",
                    [key]: value,
                });
                break;

            default:
                formik.setValues({
                    ...formik.values,
                    [key]: value,
                });
                break;
        }
    };

    const handleRenderCountryNames = () => {
        let countriesOfOperation = [...countries];
        let res = countriesOfOperation.filter((item) =>
            formik.values.countriesOfOperation.includes(item.id)
        );
        let countryNames = res.map((res) => res.name);
        return countryNames.join(", ");
    };

    const handleRenderProductsOffered = () => {
        let productsOffered = [...products];
        let res = productsOffered.filter((item) =>
            formik.values.productsOffered.includes(item.id)
        );
        let productNames = res.map((product) => product.name);
        return productNames.join(", ");
    };

    const updateFormFields = () => {
        if (state) {
            formik.setValues({
                ...formik.values,
                contactName: state?.formData?.contactName,
                email: state?.formData?.email,
                companyName: state?.formData?.companyName,
                countryOfRegistration: state?.formData?.countryRegId,
                countriesOfOperation: state?.formData?.operationalCountries,
                businessYears: state?.formData?.businessYrs,
                dAndBNumber: state?.formData?.dnbNumber,
                existingRelationshipWithAbi:
                    state?.formData?.isExistingRelation === true ? "yes" : "no",
                existingRelationshipWithAbiDetails:
                    state?.formData?.existingRelationDtls,
                isCompanyCertified:
                    state?.formData?.isOwnedByDiverse === true ? "yes" : "no",
                companySize: state?.formData?.companySizeId,
                productsOffered: state?.formData?.productsOffered,
                otherProductsOffered: state?.formData?.otherProductOffered,
                certifiedCompany:
                    state?.formData?.diverseCertById === null
                        ? ""
                        : state?.formData?.diverseCertById,
                otherOrganizationName: state?.formData?.diverseCertByOther,
            });
        }
    };

    const PrimaryInfoFormDetails = () => {
        return (
            <Paper
                variant="outlined"
                square
                style={{
                    border: "2px solid white",
                    borderRadius: "10px",
                    background: "black",
                }}
            >
                <h5>
                    <span>PRIMARY INFORMATION</span>
                </h5>
                <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 10 }}
                    sx={{ padding: "0 12%" }}
                >
                    <Grid item xs={12} sm={6} md={6} my={1}>
                        <InputLabel htmlFor="name" sx={formLabelStyling}>
                            Contact Name
                            <span className="required">*</span>
                        </InputLabel>
                        <TextField
                            id="name"
                            placeholder="Enter your name"
                            size="small"
                            fullWidth
                            value={formik.values.contactName}
                            onChange={(e) => handleInputChange("contactName", e.target.value)}
                            error={formik.touched.contactName && formik.errors.contactName}
                            helperText={
                                formik.touched.contactName ? formik.errors.contactName : null
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} my={1}>
                        <InputLabel htmlFor="email" sx={formLabelStyling}>
                            Email
                            <span className="required">*</span>
                        </InputLabel>
                        <TextField
                            id="email"
                            placeholder="Enter your email id"
                            size="small"
                            fullWidth
                            value={formik.values.email}
                            onChange={(e) => handleInputChange("email", e.target.value)}
                            error={formik.touched.email && formik.errors.email}
                            helperText={formik.touched.email ? formik.errors.email : null}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} my={1}>
                        <InputLabel htmlFor="company-name" sx={formLabelStyling}>
                            Company Name
                            <span className="required">*</span>
                        </InputLabel>
                        <TextField
                            id="company-name"
                            placeholder="Enter company name"
                            size="small"
                            fullWidth
                            value={formik.values.companyName}
                            onChange={(e) => handleInputChange("companyName", e.target.value)}
                            error={formik.touched.companyName && formik.errors.companyName}
                            helperText={
                                formik.touched.companyName ? formik.errors.companyName : null
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} my={1}>
                        <InputLabel htmlFor="country" sx={formLabelStyling}>
                            Country of Registration
                            <span className="required">*</span>
                        </InputLabel>
                        <Select
                            style={{ background: "white" }}
                            id="country"
                            size="small"
                            fullWidth
                            value={formik.values.countryOfRegistration}
                            onChange={(e) =>
                                handleInputChange("countryOfRegistration", e.target.value)
                            }
                            error={
                                formik.touched.countryOfRegistration &&
                                formik.errors.countryOfRegistration
                            }
                        >
                            {countries.length > 0 &&
                                countries.map((country) => (
                                    <MenuItem key={country.id} value={country.id}>
                                        {country.name}
                                    </MenuItem>
                                ))}
                        </Select>
                        <FormHelperText sx={{ color: "#FF0000" }}>
                            {formik.touched.countryOfRegistration
                                ? formik.errors.countryOfRegistration
                                : null}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} my={1}>
                        <InputLabel htmlFor="business-year" sx={formLabelStyling}>
                            Business Years
                            <span className="required">*</span>
                        </InputLabel>
                        <TextField
                            id="business-year"
                            placeholder="Enter a number"
                            size="small"
                            fullWidth
                            value={formik.values.businessYears}
                            onChange={(e) =>
                                handleInputChange("businessYears", e.target.value)
                            }
                            error={
                                formik.touched.businessYears && formik.errors.businessYears
                            }
                            helperText={
                                formik.touched.businessYears
                                    ? formik.errors.businessYears
                                    : null
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} my={1}>
                        <InputLabel htmlFor="country-of-operation" sx={formLabelStyling}>
                            Countries of Operation
                            <span className="required">*</span>
                        </InputLabel>
                        <Select
                            multiple
                            id="country-of-operation"
                            style={{ background: "white" }}
                            size="small"
                            fullWidth
                            renderValue={handleRenderCountryNames}
                            value={formik.values.countriesOfOperation}
                            onChange={(e) =>
                                handleInputChange("countriesOfOperation", e.target.value)
                            }
                            error={
                                formik.touched.countriesOfOperation &&
                                formik.errors.countriesOfOperation
                            }
                        >
                            {countries.length > 0 &&
                                countries.map((country) => (
                                    <MenuItem key={country.id} value={country.id}>
                                        <Checkbox
                                            checked={formik.values.countriesOfOperation.includes(
                                                country.id
                                            )}
                                        />
                                        <ListItemText primary={country.name} />
                                    </MenuItem>
                                ))}
                        </Select>
                        <FormHelperText sx={{ color: "#FF0000" }}>
                            {formik.touched.countriesOfOperation
                                ? formik.errors.countriesOfOperation
                                : null}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} my={1}>
                        <InputLabel htmlFor="dnb-number" sx={formLabelStyling}>
                            D&B Number
                        </InputLabel>
                        <TextField
                            id="dnb-number"
                            placeholder="xx-xxx-xxxx"
                            size="small"
                            fullWidth
                            value={formik.values.dAndBNumber}
                            onChange={(e) => handleInputChange("dAndBNumber", e.target.value)}
                            error={formik.touched.dAndBNumber && formik.errors.dAndBNumber}
                            helperText={
                                formik.touched.dAndBNumber ? formik.errors.dAndBNumber : null
                            }
                        />
                    </Grid>
                </Grid>
                <br />
            </Paper>
        );
    };

    const SecondaryInfoFormDetails = () => {
        return (
            <>
                <Paper
                    variant="outlined"
                    square
                    style={{
                        border: "2px solid white",
                        borderRadius: "10px",
                        background: "black",
                        marginBottom: "1rem",
                    }}
                >
                    <h5>
                        <span>SECONDARY INFORMATION</span>
                    </h5>
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 10 }}
                        sx={{ padding: "0 12%" }}
                    >
                        <Grid item xs={12} sm={6} md={6} my={1}>
                            <InputLabel
                                id="secondary-info-relationship-text"
                                sx={formLabelStyling}
                                style={{ whiteSpace: "break-spaces" }}
                            >
                                Do you have an existing relationship with ABInBev?
                                <span className="required">*</span>
                            </InputLabel>
                            <div>
                                <RadioGroup
                                    row
                                    aria-labelledby="secondary-info-relationship-text"
                                    value={formik.values.existingRelationshipWithAbi}
                                    onChange={(e) =>
                                        handleInputChange(
                                            "existingRelationshipWithAbi",
                                            e.target.value
                                        )
                                    }
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio style={{ color: "white" }} />}
                                        label="Yes"
                                        labelPlacement="end"
                                        style={{ color: "white" }}
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio style={{ color: "white" }} />}
                                        label="No"
                                        labelPlacement="end"
                                        style={{ color: "white" }}
                                    />
                                </RadioGroup>
                                {formik.values.existingRelationshipWithAbi === "yes" && (
                                    <TextField
                                        placeholder="Please enter Vendor number registered with ABI"
                                        size="small"
                                        fullWidth
                                        value={formik.values.existingRelationshipWithAbiDetails}
                                        onChange={(e) =>
                                            handleInputChange(
                                                "existingRelationshipWithAbiDetails",
                                                e.target.value
                                            )
                                        }
                                        error={
                                            formik.touched.existingRelationshipWithAbiDetails &&
                                            formik.errors.existingRelationshipWithAbiDetails
                                        }
                                        helperText={
                                            formik.touched.existingRelationshipWithAbiDetails
                                                ? formik.errors.existingRelationshipWithAbiDetails
                                                : null
                                        }
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} my={1}>
                            <InputLabel
                                htmlFor="size-of-company"
                                sx={formLabelStyling}
                                style={{ whiteSpace: "break-spaces" }}
                            >
                                Size of your company as classified in your country and
                                registration
                                <span className="required">*</span>
                            </InputLabel>
                            <Select
                                value={formik.values.companySize}
                                id="size-of-company"
                                onChange={(e) =>
                                    handleInputChange("companySize", e.target.value)
                                }
                                style={{ background: "white", marginTop: "1rem" }}
                                size="small"
                                fullWidth
                                error={formik.touched.companySize && formik.errors.companySize}
                            >
                                {companySize.length > 0 &&
                                    companySize.map((company) => (
                                        <MenuItem key={company.id} value={company.id}>
                                            {company.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText sx={{ color: "#FF0000" }}>
                                {formik.touched.companySize ? formik.errors.companySize : null}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} my={1}>
                            <InputLabel
                                shrink={false}
                                htmlFor="product-offered"
                                sx={formLabelStyling}
                            >
                                Product/Service Offered
                                <span className="required">*</span>
                            </InputLabel>
                            <Select
                                multiple
                                id="product-offered"
                                style={{ background: "white" }}
                                size="small"
                                fullWidth
                                renderValue={handleRenderProductsOffered}
                                value={formik.values.productsOffered}
                                onChange={(e) =>
                                    handleInputChange("productsOffered", e.target.value)
                                }
                                error={
                                    formik.touched.productsOffered &&
                                    formik.errors.productsOffered
                                }
                            >
                                {products.length > 0 &&
                                    products.map((product) => (
                                        <MenuItem key={product.id} value={product.id}>
                                            <Checkbox
                                                color="success"
                                                checked={formik.values.productsOffered.includes(
                                                    product.id
                                                )}
                                            />
                                            <ListItemText primary={product.name} />
                                        </MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText sx={{ color: "#FF0000" }}>
                                {formik.touched.productsOffered
                                    ? formik.errors.productsOffered
                                    : null}
                            </FormHelperText>
                            {formik.values.productsOffered &&
                                formik.values.productsOffered.includes(8) && (
                                    <TextField
                                        value={formik.values.otherProductsOffered}
                                        placeholder="Mention other products"
                                        size="small"
                                        fullWidth
                                        style={{ marginTop: "1rem" }}
                                        onChange={(e) =>
                                            handleInputChange("otherProductsOffered", e.target.value)
                                        }
                                        error={
                                            formik.touched.otherProductsOffered &&
                                            formik.errors.otherProductsOffered
                                        }
                                        helperText={
                                            formik.touched.otherProductsOffered
                                                ? formik.errors.otherProductsOffered
                                                : null
                                        }
                                    />
                                )}
                        </Grid>
                    </Grid>
                    <br />
                </Paper>
                <Paper
                    variant="outlined"
                    square
                    style={{
                        border: "2px solid white",
                        borderRadius: "10px",
                        background: "black",
                        paddingTop: "1rem",
                    }}
                >
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 10 }}
                        sx={{ padding: "0 12%" }}
                    >
                        <Grid item xs={12}>
                            <InputLabel htmlFor="certified-text" sx={formLabelStyling}>
                                Is your company owned and operated by a diverse individual which
                                is certified?
                                <span className="required">*</span>
                            </InputLabel>
                            <RadioGroup
                                row
                                aria-labelledby="certified-text"
                                value={formik.values.isCompanyCertified}
                                onChange={(e) =>
                                    handleInputChange("isCompanyCertified", e.target.value)
                                }
                            >
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio style={{ color: "white" }} />}
                                    label="Yes"
                                    labelPlacement="end"
                                    style={{ color: "white" }}
                                />
                                <FormControlLabel
                                    value="no"
                                    control={<Radio style={{ color: "white" }} />}
                                    label="No"
                                    labelPlacement="end"
                                    style={{ color: "white" }}
                                />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="white" sx={{ mt: 2, mb: 2 }}>
                                Note: We consider a diverse buisness that is atleast 51% owned,
                                operated and controlled by an individual/group which is
                                traditionally underrepresented/undeserved
                            </Typography>
                        </Grid>
                        {formik.values.isCompanyCertified === "yes" && (
                            <>
                                <Grid item xs={12}>
                                    <InputLabel
                                        htmlFor="company-certified-description"
                                        sx={formLabelStyling}
                                    >
                                        Please select the organization through which your company
                                        has been certified
                                        <span className="required">*</span>
                                    </InputLabel>
                                    <Select
                                        style={{ background: "white" }}
                                        id="company-certified-description"
                                        size="small"
                                        fullWidth
                                        value={formik.values.certifiedCompany}
                                        onChange={(e) =>
                                            handleInputChange("certifiedCompany", e.target.value)
                                        }
                                        error={
                                            formik.touched.certifiedCompany &&
                                            formik.errors.certifiedCompany
                                        }
                                    >
                                        {certifiedCompanies.length > 0 &&
                                            certifiedCompanies &&
                                            certifiedCompanies.map((company) => (
                                                <MenuItem key={company.id} value={company.id}>
                                                    {company.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    <FormHelperText sx={{ color: "#FF0000" }}>
                                        {formik.touched.certifiedCompany
                                            ? formik.errors.certifiedCompany
                                            : null}
                                    </FormHelperText>
                                </Grid>
                                {formik.values.certifiedCompany === 3 && (
                                    <Grid item xs={12}>
                                        <InputLabel
                                            htmlFor="organization-name-description"
                                            sx={formLabelStyling}
                                        >
                                            Please enter the name of the organization
                                            <span className="required">*</span>
                                        </InputLabel>
                                        <TextField
                                            value={formik.values.otherOrganizationName}
                                            id="organization-name-description"
                                            placeholder="Enter name of organization"
                                            size="small"
                                            fullWidth
                                            onChange={(e) =>
                                                handleInputChange(
                                                    "otherOrganizationName",
                                                    e.target.value
                                                )
                                            }
                                            error={
                                                formik.touched.otherOrganizationName &&
                                                formik.errors.otherOrganizationName
                                            }
                                            helperText={
                                                formik.touched.otherOrganizationName
                                                    ? formik.errors.otherOrganizationName
                                                    : null
                                            }
                                        />
                                    </Grid>
                                )}
                            </>
                        )}
                    </Grid>
                    <br />
                </Paper>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Grid item>
                        <Button
                            variant="contained"
                            sx={buttonStyling}
                            style={{ marginRight: "1rem", background: "white" }}
                            onClick={() => {
                                resetCaptcha();
                                authStatus === "authorized"
                                    ? navigate("/report")
                                    : navigate("/");
                            }}
                        >
                            BACK
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" sx={buttonStyling} type="submit">
                            SUBMIT
                        </Button>
                    </Grid>
                </div>
            </>
        );
    };

    useEffect(() => {
        fetchDropdownData();
    }, []);

    useEffect(() => {
        updateFormFields();
    }, [state]);

    return (
        <>
            {loading ? (
                <Box
                    sx={{ display: "flex", justifyContent: "center", paddingTop: "2rem" }}
                >
                    <CircularProgress sx={{ p: 5 }} />
                </Box>
            ) : (
                <>
                    {submittedFormType === "" && (
                        <>
                            <form className="form-container" onSubmit={formik.handleSubmit}>
                                <Box
                                    style={{
                                        margin: "0 12%",
                                    }}
                                >
                                    {page === 1
                                        ? PrimaryInfoFormDetails()
                                        : SecondaryInfoFormDetails()}
                                </Box>
                            </form>
                            <Stack
                                spacing={2}
                                display="flex"
                                alignItems="center"
                                style={{ marginTop: page === 2 ? "1rem" : "3rem" }}
                            >
                                <div className={classes.root}>
                                    <Pagination
                                        className={classes.root}
                                        count={2}
                                        page={page}
                                        onChange={handlePageChange}
                                        shape="rounded"
                                    />
                                </div>
                            </Stack>
                            <RecaptchaDialog
                                handleClose={handleClose}
                                open={openDialog}
                                captchaRef={captchaRef}
                                onVerifyCaptchaHandler={onVerifyCaptchaHandler}
                            />
                        </>
                    )}
                    {submittedFormType === "edit" && (
                        <TrackingIdUpdated trackingId={state?.trackingNumber} />
                    )}
                    {submittedFormType === "new" && (
                        <TrackingId trackingId={trackingId} />
                    )}
                </>
            )}
        </>
    );
};

export default Form;
