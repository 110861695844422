import { Button, Tooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import React from "react";
import pdf from "../../assets/DEI Portal.pdf";
import "./HelpToolTip.css";

export const HelpToolTip = () => {
    const helpEmail = process.env.REACT_APP_HELP_EMAIL;

    return (
        <>
            <Tooltip
                disableFocusListener
                disableTouchListener
                interactive
                placement="bottom"
                PopperProps={{
                    disablePortal: true,
                }}
                title={
                    <React.Fragment>
                        <ul
                            style={{
                                paddingInlineStart: "inherit",
                                backgroundColor: "white",
                                color: "rgba(0, 0, 0, 0.87)",
                                maxWidth: "100%",
                                fontSize: 12,
                                padding: "7px",
                                border: "2px solid gray",
                                borderRadius: "7px",
                                whiteSpace: "nowrap"
                            }}
                        >
                            <li>
                                For more information on the Supplier DEI Portal:{" "}
                                <a
                                    href={pdf}
                                    target="_blank"
                                    style={{
                                        color: "black",
                                        fontSize: "inherit",
                                        fontWeight: "bold",
                                        lineHeight: "inherit",
                                        textTransform: "inherit",
                                        textDecoration: "underline",
                                    }}
                                >
                                    Please click here
                                </a>
                            </li>
                            <li>
                                For technical assistance:{" "}
                                <a
                                    href={"mailto:" + helpEmail}
                                    style={{
                                        color: "black",
                                        fontSize: "inherit",
                                        fontWeight: "bold",
                                        lineHeight: "inherit",
                                        textTransform: "inherit",
                                        textDecoration: "underline",
                                    }}
                                >
                                    Click here to write to us{" "}
                                </a>
                            </li>
                        </ul>
                    </React.Fragment>
                }
            >
                <Button>
                    <HelpIcon className="helpToolTip" />
                </Button>
            </Tooltip>
        </>
    );
};

export default HelpToolTip;
