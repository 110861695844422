import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../../services/Auth/MsalAuth";

const textStyling = {
    margin: "2rem 0",
};

const buttonStyling = {
    marginTop: "1rem",
    background: "white",
    color: "black",
    fontFamily: "Amiko",
    fontWeight: "700",
    "&:hover": {
        background: "white",
        color: "black",
    },
};

const TrackingIdUpdated = ({ trackingId }) => {
    const navigate = useNavigate();
    const { authStatus } = useContext(AccountContext);

    const handleBackBtnClicked = () => {
        authStatus === "authorized" ? navigate("/report") : navigate("/");
    }

    return (
        <Box style={{ margin: "8% 15%" }}>
            <Paper
                variant="outlined"
                square
                style={{
                    border: "2px solid white",
                    borderRadius: "10px",
                    background: "black",
                }}
            >
                <Typography
                    variant="h6"
                    component="p"
                    align="center"
                    color="white"
                    sx={textStyling}
                >
                    Thank you for your response!
                </Typography>
                <Typography
                    variant="h5"
                    component="p"
                    align="center"
                    color="white"
                    sx={textStyling}
                >
                    The form has been updated against the tracking Id {" "}
                    <span style={{ color: "#F5E003" }}>{trackingId}</span>
                </Typography>
            </Paper>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Button
                    variant="contained"
                    sx={buttonStyling}
                    onClick={handleBackBtnClicked}
                >
                    BACK
                </Button>
            </Grid>
        </Box>
    );
};

export default TrackingIdUpdated;
