import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";

import PrivacyPolicy from "../../assets/AB InBev Privacy Statement.pdf";
import Logo from "../../assets/ABInBev_Logo.png";
import TermsAndConditions from "../../assets/Terms and Conditions.pdf";
import { AccountContext, logout } from "../../services/Auth/MsalAuth";
import HelpToolTip from "../UIElements/HelpToolTip";
import "./NavLinks.css";

const NavLinks = () => {
    const { authStatus } = useContext(AccountContext);

    const handleLogOut = () => {
        logout();
    };

    return (
        <div className="nav-links-container">
            <ul className="nav-links">
                <li className="nav-links__info">
                    <NavLink to={authStatus === "authorized" ? "/report" : "/"}>
                        <img className="nav-logo" src={Logo} alt="logo"></img>
                    </NavLink>
                    {authStatus !== "authorized" && (
                        <Link to="/" className="nav-links__home">
                            Home
                        </Link>
                    )}
                    {authStatus === "authorized" && (
                        <Link to="/report" className="nav-links__home">
                            Report
                        </Link>
                    )}
                    <Link to="/registration" className="nav-links__home">
                        Registration
                    </Link>
                    <Link to="/track" className="nav-links__home">
                        Track
                    </Link>
                </li>
                <div className="nav-links__info">
                    <li>
                        <HelpToolTip />
                    </li>
                    <li>
                        <a href={PrivacyPolicy} target="_blank" rel="noreferrer">
                            Privacy Policy
                        </a>
                    </li>
                    <li>
                        <a href={TermsAndConditions} target="_blank" rel="noreferrer">
                            Terms and Conditions
                        </a>
                    </li>
                    {authStatus === "authorized" && (
                        <li>
                            <a onClick={handleLogOut}>LOGOUT</a>
                        </li>
                    )}
                </div>
            </ul>
        </div>
    );
};

export default NavLinks;
