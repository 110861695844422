import * as Yup from "yup";

export const validationSchema = () => {
    return Yup.object().shape({
        contactName: Yup.string().max(200).required("Contact Name is required"),
        email: Yup.string().required("Email is required").email("Email is invalid"),
        companyName: Yup.string().max(200).required("Company Name is required"),
        countryOfRegistration: Yup.string().required("Country is required"),
        businessYears: Yup.number()
            .min(1, "Business Years should be greater than 0")
            .required("Buisness Years are required"),
        countriesOfOperation: Yup.array()
            .min(1, "Minimum one country should be selected")
            .required(),
        dAndBNumber: Yup.string().matches(
            /^[0-9]{2}-[0-9]{3}-[0-9]{4}$/,
            "Invalid number"
        ),
        existingRelationshipWithAbiDetails: Yup.string().when(
            "existingRelationshipWithAbi",
            {
                is: "yes",
                then: Yup.string().required("Details are required"),
            }
        ),
        companySize: Yup.string().required("Company Size is required"),
        productsOffered: Yup.array()
            .min(1, "Minimum one product should be selected")
            .required(),
        otherProductsOffered: Yup.string().when(
            "productsOffered",
            (productsOffered) => {
                return productsOffered.includes(8)
                    ? Yup.string().required("Mention other products")
                    : Yup.string().notRequired();
            }
        ),
        certifiedCompany: Yup.string().when("isCompanyCertified", {
            is: "yes",
            then: Yup.string().required("Select the organization"),
        }),
        otherOrganizationName: Yup.string().when("certifiedCompany", {
            is: "3",
            then: Yup.string().required("Organization name is required"),
        }),
    });
};