import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Button, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReCaptcha from "../../components/ReCaptcha/ReCaptcha";
import { AppData } from "../../services/Auth/AppData";
import { AccountContext } from "../../services/Auth/MsalAuth";

import "./Track.css";

const Title = ({ text, variant, component }) => (
    <Typography
        variant={variant}
        component={component}
        style={{
            fontFamily: "Amiko",
            fontSize: "40px",
            fontWeight: "700",
            fontStyle: "normal",
            color: "#F5E003",
        }}
    >
        {text}
    </Typography>
);

const buttonStyling = {
    minWidth: "283px",
    maxWidth: "310px",
    background: "black",
    color: "white",
    border: "2px solid white",
    marginTop: "1rem",
    "&:hover": {
        color: "#F5E003 ",
        background: "black ",
        border: "2px solid #F5E003",
    },
    "&:disabled": {
        color: "gray",
        border: "2px solid gray",
    },
};

const Track = () => {
    const navigate = useNavigate();
    const appData = new AppData();
    const { authStatus } = useContext(AccountContext);
    const captchaRef = useRef(null);
    const [captchaValue, setCaptchaValue] = useState("");
    const [isSearchError, setIsSearchError] = useState(false);

    const onVerifyCaptchaHandler = (value) => {
        setCaptchaValue(value);
    };

    const handleBackToHome = () => {
        setCaptchaValue("");
        setIsSearchError(false);
        authStatus === "authorized" ? navigate("/report") : navigate("/");
    };

    const handleTrackForm = () => {
        const payload = {
            trackingNumber: formik.values.trackingNumber,
            email: formik.values.email,
        };

        appData
            .trackDniForm(payload)
            .then((response) => {
                let { trackingNumber } = payload;
                let mode = "edit";
                let formData = response.data.data;
                navigate("/registration", {
                    state: { formData, mode, trackingNumber },
                });
            })
            .catch((error) => {
                console.log("error", error);
                console.error(error);
                setIsSearchError(true);
            });
    };

    const formik = useFormik({
        initialValues: {
            trackingNumber: "",
            email: "",
        },
        onSubmit: handleTrackForm,
    });

    const handleInputChange = (key, value) => {
        formik.setValues({
            ...formik.values,
            [key]: value,
        });
    };
    return (
        <div className="track-container">
            <Title text="Track a record" variant="h6" component="p" />
            <TextField
                hiddenLabel
                id="tracking-id"
                style={{ marginTop: "1rem", alignItems: "center" }}
                InputProps={{ style: { maxWidth: "310px" } }}
                placeholder="Tracking ID"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => handleInputChange("trackingNumber", e.target.value)}
            />
            <TextField
                hiddenLabel
                id="email"
                style={{ marginTop: "1rem", alignItems: "center" }}
                InputProps={{ style: { maxWidth: "310px" } }}
                placeholder="Email ID"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => handleInputChange("email", e.target.value)}
            />
            {isSearchError && (
                <>
                    <Typography
                        color="#FF0000"
                        style={{
                            fontSize: "13px",
                            marginTop: "5px",
                            fontFamily: "Amiko",
                            fontWeight: "600",
                            whiteSpace: "break-spaces",
                        }}
                    >
                        <WarningAmberIcon sx={{ color: "#FF0000", height: "14px" }} />
                        Information entered is incorrect. Please review
                    </Typography>
                </>
            )}
            <div style={{ marginTop: "1rem" }}>
                <ReCaptcha captchaRef={captchaRef} onChange={onVerifyCaptchaHandler} />
            </div>
            <Button
                sx={buttonStyling}
                onClick={handleTrackForm}
                fullWidth
                disabled={!captchaValue}
            >
                SEARCH
            </Button>
            <Button
                sx={buttonStyling}
                onClick={handleBackToHome}
                fullWidth
            >
                BACK
            </Button>
        </div>
    );
};

export default Track;
