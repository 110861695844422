import Dialog from "@mui/material/Dialog";
import * as React from "react";
import ReCaptcha from "../ReCaptcha/ReCaptcha";

export default function RecaptchaDialog(props) {
    const { handleClose, open, captchaRef, onVerifyCaptchaHandler } = props;

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            PaperProps={{
                style: {
                    padding: "20px",
                },
            }}
        >
            <ReCaptcha captchaRef={captchaRef} onChange={onVerifyCaptchaHandler} />
        </Dialog>
    );
}
