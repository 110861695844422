import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const ReCaptcha = (props) => {
    return (
        <div>
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={props.captchaRef}
                onChange={props.onChange}
            />
        </div>
    );
};

export default ReCaptcha;
