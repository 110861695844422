import { ThemeProvider } from "@mui/material/styles";
import createTheme from "@mui/material/styles/createTheme";
import React, { useContext, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import background from "./assets/image.png";
import MainNavigation from "./components/Navigation/MainNavigation";
import { withSnackbar } from "./components/Snackbar/SnackbarHoc";
import Form from "./pages/Form/Form";
import Login from "./pages/Login/Login";
import NotFound from "./pages/NotFound/NotFound";
import Report from "./pages/Report/Report";
import Track from "./pages/Track/Track";
import { AccountContext } from "./services/Auth/MsalAuth";

export const ApiContext = React.createContext();

const theme = createTheme({
  components: {
    // Name of the component
    MuiInputBase: {
      styleOverrides: {
        root: {
          // Some CSS
          background: "white",
          borderRadius: "5px",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: "3px 0 0 0",
          color: "#FF0000 !important",
        },
      },
    },
  },
});

function App({ snackbarShowMessage }) {
  const { authStatus } = useContext(AccountContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (authStatus === "authorized") {
      navigate("/report");
    } else if (authStatus === "logged-out" || authStatus === "unauthorized") {
      navigate("/");
    }
  }, [authStatus]);

  return (
    <ThemeProvider theme={theme}>
      <ApiContext.Provider value={{ snackbarShowMessage }}>
        <MainNavigation />
        <main
          style={{
            overflow: "auto",
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "100vh",
          }}
        >
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/registration" element={<Form />} />
            <Route path="/track" element={<Track />} />
            {authStatus === "authorized" && (
              <Route path="/report" element={<Report />} />
            )}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
      </ApiContext.Provider>
    </ThemeProvider>
  );
}

export default withSnackbar(App);
