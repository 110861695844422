import { Box, Typography } from "@mui/material";
import React from "react";

import logo404 from "../../assets/404NotFound.svg";

function NotFound() {
    return (
        <div className="wrapper">
            <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center', paddingTop: "4rem" }}>
                <Typography variant="h3" paragraph>
                    Sorry, page not found!
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                    Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL?
                    Be sure to check your spelling.
                </Typography>
            </Box>
            <Box
                component="img"
                src={logo404}
                sx={{ height: 260, mx: 'auto', my: { xs: 3, sm: 5 }, display: "flex" }}
            />
        </div>
    );
}

export default NotFound;
