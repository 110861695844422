import axios from "axios";
import React from "react";
import { GetWebApiBaseUri } from "./AppSettingsService";
import { requestSilentTokens } from "./MsalAuth";

export class AppData extends React.Component {
    constructor(props) {
        super(props);
        this.authToken = props?.account;
        this.api = axios.create({});
        // this.api.interceptors.request.use((config) => {
        //     const secondsSinceEpoch = Math.round(Date.now() / 1000);
        //     const authHeader = 'ABI_GUEST_DNI_USER:' + secondsSinceEpoch.toString();
        //     const encryptedAuthHeader = EncryptWithPublicKey(authHeader);
        //     config.headers['Authorization'] = 'Basic ' + encryptedAuthHeader;
        //     return config;
        // });
    }

    static API_ENDPOINT = GetWebApiBaseUri();

    addDniFormRecord(submitPayload) {
        return this.api({
            method: "POST",
            url: `${AppData.API_ENDPOINT}DniForm`,
            data: submitPayload,
        });
    }

    getCompanySize() {
        return this.api({
            method: "GET",
            url: `${AppData.API_ENDPOINT}CompanySize/names`,
        });
    }

    getCountry() {
        return this.api({
            method: "GET",
            url: `${AppData.API_ENDPOINT}Country/names`,
        });
    }

    getProduct() {
        return this.api({
            method: "GET",
            url: `${AppData.API_ENDPOINT}Product/names`,
        });
    }

    getCertifiedCompany() {
        return this.api({
            method: "GET",
            url: `${AppData.API_ENDPOINT}DiverseCertification/names`,
        });
    }

    trackDniForm(submitPayload) {
        return this.api({
            method: "POST",
            url: `${AppData.API_ENDPOINT}DniForm/track`,
            data: submitPayload,
        });
    }

    updateDniForm(submitPayload) {
        return this.api({
            method: "PUT",
            url: `${AppData.API_ENDPOINT}DniForm`,
            data: submitPayload,
        });
    }

    async getDniReport(submitPayload) {
        if (
            this.authToken?.tokenExpirationDate?.getTime() <= new Date().getTime()
        ) {
            await requestSilentTokens().then((res) => {
                this.authToken = {
                    apiToken: res.apiToken,
                    graphToken: res.graphToken,
                    tokenExpirationDate: res.tokenExpirationDate,
                };
            });
        }
        return this.api({
            method: "POST",
            url: `${AppData.API_ENDPOINT}DniForm/report`,
            data: submitPayload,
            headers: {
                authorization: `Bearer ${this.authToken.apiToken}`,
            },
        });
    }
}
